.profile-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000; /* Ensure it's above everything else */
}

.profile-view-modal {
  background: #1f1f1f;
  border-radius: 10px;
  padding: 32px;
  max-width: 400px; /* Adjust based on your layout needs */
  width: 90%; /* Responsive width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; /* Added gap for consistent spacing */
}

.profile-view-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; /* Space between avatar and username */
}

.profile-avatar {
  background: #ccc; /* Placeholder color, replace with actual image */
  border-radius: 50%;
  width: 80px; /* Adjust size as needed */
  height: 80px;
}

.profile-username {
  font-size: 24px;
  color: white;
  text-align: center;
  margin: 0px;
}

.profile-bio {
  font-size: 14px;
  text-align: center;
  color: white;
}

.follow-buttonProfile {
  background-color: white;
  color: black;
  border: none;
  width: 100%;
  padding: 8px 32px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.follow-buttonProfile:hover {
  background-color: #f0f0f0; /* Slightly darker on hover */
}

.follow-buttonProfile:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.profile-socials {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between social items */
}

.social-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.social-username {
  font-weight: bold;
  font-size: 16px;
  color: white;
}

.connect-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; /* Space between button and cannot-connect message */
}

.cannot-connect-message {
  color: rgb(156, 156, 156);
  font-size: 12px;
  text-align: center;
}

.profile-showcase {
  display: flex;
  gap: 8px; /* Space between showcase items */
  width: 100%;
}

.statusText {
  font-size: 16px;
  color: white;
  text-align: center;
}

.showcase-item {
  flex: 1;
  height: 100px;
  background-color: #c4c4c4;
  border-radius: 8px;
}

.showcase-label {
  font-weight: bold;
  font-size: 16px;
  color: white;
  width: 100%;
}

.profile-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
}

.tooltip-containerProfile {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
