/* PointsNotification.css */
.points-notification {
    position: absolute;
    top: 88px; /* Adjust this based on your menu position */
    right: 32px;
    display: flex;
    align-items: center;
    z-index: 1200;
    background-color: rgba(33, 33, 33, 0.9); /* Dark background */
    color: #ffffff;
    border-radius: 64px;
    padding: 10px 20px;
    font-family: 'Work Sans';
    font-size: 16px;
    max-width: 320px;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.points-notification.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.points-notification.fade-out {
    opacity: 0;
    transform: translateY(-20px);
}

.notification-icon {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-content .reason {
    font-weight: 500;
    font-size: 14px;
    color: #a9a9a9;
}

.notification-content .points-earned {
    font-size: 16px;
}