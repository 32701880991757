/* ProximityChat.css */

.dialogue-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: fixed;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    background: rgba(14, 14, 14, 0.85);
    padding: 24px 20px 20px 20px;
    border-radius: 8px;
    gap: 0px;
}

.dialogue-box.mobile {
    left: auto;
    right: 12px;
    bottom: 24px;
    transform: none;
    padding: 16px;
}

.remove-button {
    position: absolute;
    top: 0px;
    left: 12px;
    cursor: pointer;
    color: white;
    font-size: 24px;
    font-weight: 800;
}

.user-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 600px;
}

.user-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(21, 21, 21, 0.9);
    padding: 8px;
    border-radius: 8px;
    width: 192px;
    height: 124px;
    color: white;
    margin: 0 8px;
}

.close-button {
    position: absolute;
    width: 16px;
    height: 16px;
    cursor: pointer;
    top: 4px;
    right: 8px;
}

.avatar {
    width: 64px;
    height: 64px;
    background-color: grey;
    border-radius: 50%;
    margin-bottom: 5px;
    cursor: pointer;
}

.follow-button {
    background-color: white;
    color: black;
    border: none;
    padding: 4px 20px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 18px;
    cursor: pointer;
    margin-top: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.follow-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.follow-button:hover {
    background-color: #f0f0f0;
}

.active-speaker {
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
    border: 1px solid rgb(155, 155, 155);
}

.username {
    font-size: 14px;
    cursor: pointer;
}

.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: hidden;
    width: 220px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.chevron {
    cursor: pointer;
    color: white;
    font-size: 24px;
    margin: 0 4px;
    user-select: none;
}

/* New styles for the join prompt state */

.join-voice-chat {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(21, 21, 21, 0.9);
    padding: 20px;
    border-radius: 8px;
    color: white;
    width: 312px;
    gap: 16px;
}

.join-title {
    font-size: 20px;
    font-weight: 600;
}

.join-description {
    font-size: 14px;
    margin-bottom: 16px;
}

.join-buttons {
    display: flex;
    gap: 16px;
}

.yes-button,
.no-button {
    border: 2px solid white;
    border-radius: 18px;
    padding: 8px 16px;
    background: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.yes-button:hover,
.no-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
