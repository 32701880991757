.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #1f1f1f;
    border-radius: 12px;
    padding: 24px;
    max-width: 600px; /* Increase the max-width if needed */
    width: 80%;
    height: 80%; /* Adjust the height to make the modal taller */
    z-index: 2001;
    position: relative;
  }
  
  .modal-close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
  }
  
  .fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
  }