.profile-area {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 22px;
    justify-content: flex-end;
    position: relative;
  }
  
.lorem-wrapper {
    all: unset;
    align-items: center;
    border: 3px solid;
    border-color: #ffffff;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    justify-content: center;
    overflow: hidden;
    padding: 16px 24px;
    position: relative;
    width: 100px;
  }
  
.lorem {
    color: #ffffff;
    font-family: "Work Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .div-wrapper {
    all: unset;
    align-items: center;
    background-color: #3e4448;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    justify-content: center;
    overflow: hidden;
    padding: 16px 24px;
    position: relative;
    width: 100px;
  }
  
  .lorem-2 {
    color: #ffffff;
    font-family: "Work Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }