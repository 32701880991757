/* SideDrawer.css */

.drawer-enter {
    transform: translateX(100%);
}

.drawer-enter-active {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}

.drawer-exit {
    transform: translateX(0);
}

.drawer-exit-active {
    transform: translateX(100%);
    transition: transform 200ms ease-out;
}

.sideDrawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    box-sizing: border-box;
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    z-index: 1002;
    /* Ensure it is above everything else */
}

.drawerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    /* Just below the side drawer */
}

.closeButtonContainer {
    display: flex;
    justify-content: flex-end;
    /* pushes the close button to the right */
}

.closeButton {
    cursor: pointer;
    font-size: 30px;
    color: white;
    margin-bottom: 20px;
}

.navItem {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    margin: 0px 20px;
    font-family: 'Work Sans';
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 0px;
}
