.fade-enter {
    opacity: 0;
    transform: translateY(20px); /* Start 20px lower */
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0); /* Slide up to the original position */
    transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0); /* Start from the original position */
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(20px); /* Slide down as it fades out */
    transition: opacity 200ms ease-out, transform 200ms ease-out;
}