.microphone-menu {
    width: 300px;
    border-radius: 12px;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-family: Arial, sans-serif;
    position: absolute;
    top: 80px;
    right: 36px;
    z-index: 1200;
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px); /* Fallback for Safari */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some depth */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: Add a border */
}

.dropdown-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 300ms ease, opacity 300ms ease;
  }
  
  .dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 300ms ease, opacity 300ms ease;
  }
  

.menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.menu-title {
    font-weight: 500;
    font-size: 24px;
    font-family: "Work Sans";
}

.menu-divider {
    height: 1px;
    background-color: #555;
    margin: 12px 0;
}

.menu-item-mic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 8px;
    border-radius: 8px;
}

.menu-item-join-leave {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 8px;
    border-radius: 8px;
    cursor: pointer;
}

.menu-item-text {
    font-size: 20px;
    font-family: 'Work Sans';
}

.mic-icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    cursor: pointer;
}

.toggle-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.0);
    border-radius: 8px;
    cursor: pointer;
}
